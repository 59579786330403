/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import wrapWithProvider from './wrap-with-provider';
import './node_modules/bootstrap/dist/css/bootstrap.min.css';
import './src/stylesheets/basic.scss';
import JSZipUtils from 'jszip-utils';
window.JSZipUtils = JSZipUtils;

export const wrapRootElement = wrapWithProvider;