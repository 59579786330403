import { createStore as reduxCreateStore } from 'redux';
import * as types from '../constants/ActionTypes';

const reducer = (state, action) => {
    if (action.type === types.SET_HAMBURGER_MENU_STATE) {
        return Object.assign({}, state, { isOpen: action.isOpen });
    }

    return state;
};

const initialState = {
    isOpen: false
};

const createStore = () => reduxCreateStore(reducer, initialState)

export default createStore;